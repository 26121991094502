import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import styles from './pages.module.css'

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `Yves De Vrient`,
        `Loodgieter Yves De Vrient`,
        `De Vrient`,
        `Vrient`,
        `Loodgieter De Vrient`,
        `loodgieter`,
        `loodgieter Kruisem`,
        `loodgieter Zingem`,
        `Loodgieter Oudenaarde`,
        `Verwarming`,
        `Sanitair`,
        `Chauffage`,
        `Hernieuwbare energie`,
        `Kruisem`,
        `Zingem`,
        `Viessman`,
        `Bulex`,
        `Erkend Cerga Installateur`,
        `chauffagist`,
        `chauffage`,
        `9750`,
        `sanitair`,
        `badkamerrenovatie`,
        `centrale verwarming installeren`,
      ]}
    />
    <div>
      <div>
        <h1>Yves De Vrient te Kruisem (Zingem) - Sanitair en Verwarming</h1>
      </div>
      <div>
        <h2>Diensten</h2>
        <ul className={styles.text__indent}>
          <li className={styles.text__indent__item}>
            <p className={styles.summary__header}>Verwarming</p>
            <p className={styles.summary__text}>
              Denkt u aan vernieuwing van uw cv-installatie, we komen bij u
              langs om te bespreken wat de mogelijkheden zijn.{' '}
            </p>
          </li>
          <li className={styles.text__indent__item}>
            <p className={styles.summary__header}>Sanitair</p>
            <p className={styles.summary__text}>
              Denkt u aan renovatie van uw badkamer of heeft u een nieuwbouw,
              wij staan u graag bij voor advies
            </p>
          </li>
          <li className={styles.text__indent__item}>
            <p className={styles.summary__header}>Hernieuwbare energie</p>
            <p className={styles.summary__text}>
              Met oog op de toekomst kunt u bij ons terecht voor hernieuwbare
              energie. Wij beschikken over een tal van certificaten.
            </p>
          </li>
        </ul>
      </div>
      <div>
        <h2>Service</h2>
        <p
          style={{
            fontSize: `0.95rem`,
          }}
        >
          Heeft u een lek in uw waterleiding, een lekkende kraan of uw
          verwarming doet het niet meer? Wij proberen u zo snel mogelijk te
          helpen.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
